.contact-cards {
    width: 100%;
    padding: 3rem 0 ;
    background-color: white;
    margin: auto;
}

.contact-card {
    width: 250px;
}

.contact-card h1 {
    padding: 1rem 0 0 0;
}

.contact-cards-contents {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.contact-card-icon {
    width: 100px;
    margin: auto;
}

.contact-card-info {
    text-align: center;
}

.contact-card-icon img {
    width: 100%;
}

@media screen and (max-width:700px) {
    .contact-card-icon {
        width: 50px;
    }

    .contact-card h1 {
        font-size: 1rem;
    }
}