@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    position:  absolute;
    z-index: 10;
    background: #1b1a1a62;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
padding: 0 2rem;
}

.navbar-logo {
  width: 200px;
  z-index: 2;
}
.navbar-logo img {
  width: 100%;
}

.navbar .icon {
  font-size: 1.4rem;
  color: rgb(225, 228, 235);
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: rgb(215, 218, 224);
}

.nav-btn button {
  background-color: rgb(54, 97, 190);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  text-transform: uppercase;
  padding: .7rem 2.5rem;
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    margin: auto;
}


.navbar li {
    width: max-content;
    padding: .5rem 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.navbar li a {
    font-weight: 600;
    font-size: 16px;
    color: rgb(224, 210, 210);
    letter-spacing: 1px;
}

  .menu-item .sub__menus li a {
    color: #353535;
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: white;
    border: 1px solid lavender;
    border-radius: 10px;
    width: 240px;
    left: -17px;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 10px;
    display: none;
  }


  
@media screen and (max-width: 940px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .nav-btn {
      display: none;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: var(--primary-color);
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }

      .sub__menus {
        width: 250px;
      }

      .navbar .navbar-container {
        justify-content: space-between;
      }

}





