@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');


.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(0, 0, 0, 0.151);
}

.hero:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero-bg.jpg') no-repeat center top/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}


.hero .hero-container {
    width: 100%;
}

.hero .content {
  width: 50%;
  top: 35%;
  position: absolute;
  padding: 1rem;
  text-align: center;
}


.hero h1 {
    font-size: 5rem;
    color: rgb(255, 255, 255);
    padding: .5rem;
    letter-spacing: 2px;
    font-family: 'Roboto Slab', serif;
    font-weight: 900;
}

.hero p {
    font-size: 1rem;
    color: rgb(255, 255, 255);
    padding: .5rem;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .hero .content {
    width: 100%;
    top: 50%;
    left: 0%;
  }

    .hero h1 {
        font-size: 3rem;
        text-align: center;
    }


    .hero p {
      font-size: 1rem;
      color: rgb(255, 255, 255);
  }
  

}
