@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.mission {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(22, 22, 22, 0.342);
  }
  
  .mission:before {
    content: '';
    position: absolute;
    background: url('../../assets/mission.jpeg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


  .mission .mission-content {
    display: block;
    position: absolute;
    top: 60%;
    padding: 1rem;
    width: 50%;
}

.mission-accent {
    width: 100%;
    margin: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: -1;
}

.mission-accent img {
    width: 100%;
    opacity: 0.6;
}

.mission h1 {
    font-size: 5rem;
    font-family: 'Indie Flower', cursive;
    color: rgb(255, 255, 255);
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.mission h2 {
    color: white;
    font-size: 1.5rem;
}

.mission h1 {
    font-size: 3rem;
}


.mission .mission-content {
    width: 70%;
}
}


@media screen and (max-width:700px) {
    .mission .mission-content {
        width: 90%;
    }
}